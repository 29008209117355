import { jackpotVoteUpdated } from "../../Components/GreenDashboard/dashboard.slice.js";
import {
  fetchJackPotStart,
  fetchJackPotFailure,
  myPicksOnJackPot,
  createVotePopUpstatus,
  mypotOtherPlayerPrediction,
  updateJackpotVote,
} from "../../Components/JackPotViewDetail/jackpot.slice.js";
import { jackpotOtherPlayerPrediction } from "../../Components/MyPotViewDetail/myPot.slice.js";

import { postData, getData, putData } from "../api";

export const fetchJackPotMyPick = (URL, tokenInfo) => async (dispatch) => {
  try {
    dispatch(fetchJackPotStart());
    const response = await getData(URL);
    if (response.status === "Success") {
      dispatch(myPicksOnJackPot(response?.data));
    }
  } catch (error) {
    dispatch(fetchJackPotFailure(error.message));
  }
};

export const JackPotVoteNow = (URL, voteInfo) => async (dispatch) => {
  try {
    dispatch(fetchJackPotStart());
    let response = await putData(URL, voteInfo);
    if (response.status === "success") {
      const getPotId = URL.split("/")[3];
      voteInfo.potId = getPotId;
      dispatch(updateJackpotVote(voteInfo));
      dispatch(jackpotVoteUpdated(voteInfo));
    }
  } catch (error) {
    dispatch(fetchJackPotFailure(error.message));
  }
};

// fetchJackPotVoteInfo

export const fetchJackPotVoteInfo = (URL, pickId) => async (dispatch) => {
  try {
    dispatch(fetchJackPotStart());

    const response = await getData(URL);
    const res = response?.data?.voted.filter(
      (ele) => ele.pick_id === Number(pickId.pickId)
    );
    let is_vote = res[0].vote;
    response.is_vote = is_vote;
    dispatch(createVotePopUpstatus(response));
  } catch (error) {
    dispatch(fetchJackPotFailure(error.message));
  }
};

export const fetchOtherPlayerPrediction = (URL) => async (dispatch) => {
  try {
    dispatch(fetchJackPotStart());
    const response = await getData(URL);
    if (response.length > 0) {
      dispatch(mypotOtherPlayerPrediction(response));
    }
  } catch (error) {
    dispatch(fetchJackPotFailure(error.message));
  }
};

export const fetchOtherPlayerPredictionMyPot = (URL) => async (dispatch) => {
  try {
    dispatch(fetchJackPotStart());
    const response = await getData(URL);
    return response;
  } catch (error) {
    dispatch(fetchJackPotFailure(error.message));
  }
};

export const fetchPotInfoJackPotView = (URL, tokenInfo) => async (dispatch) => {
  try {
    const response = await getData(URL);
    if (response.status === "Success") {
      return response;
    }
  } catch (error) {
    return "Pot info error";
  }
};

import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import useFormikValidation from "../../vallidation/useFormikValidation";
import { sinUpValidationSchema } from "../../vallidation/loginSchema";
import Card from "../../Elements/Card";
import Input from "../../Elements/Input";
import Button from "../../Elements/Button";
import AuthLayout from "../AuthLayout/AuthLayout";
import { signupNewUser } from "../../services/backenApiCall";
import { clearUserFailure, updateErrorValues } from "./signup.slice";

import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";

import { FaEye, FaEyeSlash } from "react-icons/fa"; // Import icons
import { FaQuestion } from "react-icons/fa";

import { Flip, toast, ToastContainer } from "react-toastify";
import CryptoJS from "crypto-js";

import "./signup.css";
import Info from "../../assets/images/icons8-info.svg";

function Sinup() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [saltValue, setSaltValue] = useState("opk3fa_vc9nvefxy");

  const [countryName, setCountryName] = useState("");

  const signupUserInfo = useSelector((state) => {
    return state?.signUp?.data?.email;
  });

  const signupUserError = useSelector((state) => {
    return state?.signUp?.error;
  });

  useEffect(() => {
    if (signupUserError) {
      toast.error(signupUserError, {
        onClose: handleToastClose,
        autoClose: 2000,
      });
      // toast.success(succFromServer, {
      //   onClose: handleToastClose,
      //   autoClose: 2000,
      // });
    }
  }, [signupUserError]);

  const handleToastClose = () => {
    dispatch(updateErrorValues());
  };

  useEffect(() => {
    if (signupUserInfo) {
      navigate("/verification");
      localStorage.removeItem("countdownEndTime");
    }
  }, [signupUserInfo]);

  const handleSubmit = (values) => {
    const {
      firstName,
      lastName,
      userName,
      mobileNumber,
      email,
      password,
      confirmPassword,
    } = formik.values;

    let salt = process.env.REACT_APP_DEV_SOLT;
    let ivCode = process.env.REACT_APP_DEV_IVCODE;

    var derived_key = CryptoJS.enc.Base64.parse(salt);
    var iv = CryptoJS.enc.Utf8.parse(ivCode);
    var hashPassword = CryptoJS.AES.encrypt(password.trim(), derived_key, {
      iv: iv,
      mode: CryptoJS.mode.CBC,
    }).toString();

    let data = {
      first_name: firstName.trim(), // to create this format for backend
      last_name: lastName.trim(),
      username: userName.trim(),
      mobile_number: mobileNumber.trim(),
      country_name: countryName.trim(),
      email: email.trim(),
      password: hashPassword,
      confirm_password: confirmPassword.trim(),
    };
    dispatch(signupNewUser("/accounts/user/create", data));
  };

  const formik = useFormikValidation(
    {
      firstName: "",
      lastName: "",
      userName: "",
      mobileNumber: "",
      email: "",
      password: "",
      confirmPassword: "",
    },
    sinUpValidationSchema,
    handleSubmit
  );

  const handleFieldChange = () => {
    dispatch(clearUserFailure(""));
  };

  const [showPassword, setShowPassword] = useState(true); // State variable
  const [confrimShowPassword, setConfrimShowPassword] = useState(true); // State variable

  const togglePasswordVisibility = () => {
    setShowPassword((prevState) => !prevState); // Toggle visibility
  };

  const toggleConfrimPasswordVisibility = () => {
    setConfrimShowPassword((prevState) => !prevState);
  };

  return (
    // <AuthLayout>
    //   <ToastContainer
    //     position="top-center"
    //     autoClose={5000}
    //     hideProgressBar={false}
    //     newestOnTop={false}
    //     closeOnClick
    //     rtl={false}
    //     pauseOnFocusLoss
    //     draggable
    //     pauseOnHover
    //     style={{ zIndex: 9999 }}
    //     className="mobile-s:w-[70%] mobile-s:ml-[15%] tablet:w-[40%] laptop-s:w-[25%] mobile-s:text-[12px] mobile-m:!text-[14px] tablet:ml-[1%]"
    //   />
    //   <div className="overflow-auto mobile-s:mt-[5%] tablet:mt-[0%] tablet:overflow-hidden w-full">
    //     <Card
    //       className={`m-auto py-[3%] px-[4%] font-[inter]
    // mobile-s:mt-[12%] mobile-m:mt-[25%]  mobile-l:mt-[25%] tablet:!mx-auto tablet:!my-auto
    //  mobile-l:mb-[20%] mobile-s:!mb-[20%] mobile-m:mb-[20%]
    //   mobile-s:w-[90%] bg-[#FFFFFF] shadow-md
    // rounded-[12px] laptop-l:w-[50%] laptop-min:w-[55%] laptop-s:w-[65%]
    // tablet:w-[80%] desktop:w-[40%] flex flex-col justify-center over`}
    //       style={{
    //         maxHeight: "100vh", // Set the max height to the viewport height
    //       }}
    //     >
    //       <div className="w-full m-auto pt-5 items-center">
    //         <h2 className="text-[28px] font-semibold mb-3">Sign Up</h2>
    //         <p className=" text-[15px] text-[#000000] text-opacity-50 mb-4">
    //           Please enter the below details to create an account
    //         </p>
    //         <form
    //           onSubmit={(e) => {
    //             e.preventDefault();
    //             formik.handleSubmit();
    //           }}
    //           className="w-full"
    //         >
    //           <div className="flex mobile-s:block tablet:flex gap-4">
    //             <div className="h-[45px] rounded-[5px] w-[100%] mt-[2%] p-1 tablet:w-[400px]">
    //               <Input
    //                 text="text"
    //                 placeholder="First Name"
    //                 className="text-gray-950 p-2 w-[100%] rounded-[5px] border border-solid border-[#CACACA] text-[15px] outline-none input-focused"
    //                 id="firstName"
    //                 name="firstName"
    //                 value={formik.values.firstName}
    //                 onChange={(e) => {
    //                   const sanitizedValue = e.target.value.replace(
    //                     /[^a-zA-Z\s]/g,
    //                     ""
    //                   ); // Sanitize input
    //                   formik.setFieldValue(e.target.name, sanitizedValue); // Update formik value
    //                   handleFieldChange();
    //                 }}
    //                 onBlur={formik.handleBlur}
    //                 autocomplete="off"
    //               />
    //               {formik.touched.firstName && formik.errors.firstName && (
    //                 <span className="text-[#EF4444] mobile-s:text-[13px]">
    //                   {formik.errors.firstName}
    //                 </span>
    //               )}
    //             </div>
    //             <div className="h-[45px] rounded-[5px] w-[100%] mt-[10%] tablet:mt-[2%] p-1 tablet:w-[400px]">
    //               <Input
    //                 text="text"
    //                 placeholder="Last Name"
    //                 className=" text-gray-950 p-2 w-[100%] rounded-[5px] border border-solid
    //                  border-[#CACACA] text-[15px] outline-none input-focused"
    //                 id="lastName"
    //                 name="lastName"
    //                 value={formik.values.lastName}
    //                 onChange={(e) => {
    //                   const sanitizedValue = e.target.value.replace(
    //                     /[^a-zA-Z\s]/g,
    //                     ""
    //                   ); // Sanitize input
    //                   formik.setFieldValue(e.target.name, sanitizedValue); // Update formik value
    //                   handleFieldChange(); // Clear server error on field change
    //                 }}
    //                 onBlur={formik.handleBlur}
    //                 autocomplete="off" // Disable auto-suggestion
    //               />
    //               {formik.touched.lastName && formik.errors.lastName && (
    //                 <span className="text-[#EF4444] mt-1 mobile-s:text-[13px]">
    //                   {formik.errors.lastName}
    //                 </span>
    //               )}
    //             </div>
    //             <div className="h-[45px] rounded-[5px] w-[100%] mt-[2%] mobile-s:mt-[10%] tablet:mt-[2%] p-1 tablet:w-[400px]">
    //               <Input
    //                 text="text"
    //                 placeholder="Display Name"
    //                 className="text-gray-950 p-2 w-[100%] rounded-[5px] border border-solid border-[#CACACA] text-[15px] outline-none input-focused"
    //                 id="userName"
    //                 name="userName"
    //                 value={formik.values.userName}
    //                 onChange={(e) => {
    //                   formik.handleChange(e);
    //                   handleFieldChange();
    //                 }}
    //                 onBlur={formik.handleBlur}
    //                 autocomplete="off"
    //               />
    //               {formik.touched.userName && formik.errors.userName && (
    //                 <span className="text-[#EF4444]  mt-1 mobile-s:text-[13px]">
    //                   {formik.errors.userName}
    //                 </span>
    //               )}
    //             </div>
    //           </div>
    //           <div className="flex mobile-s:block tablet:flex gap-4 tablet:mt-[2%] tablet:mb-[2%]">
    //             <div
    //               className="w-[100%] h-[45px] rounded-[5px] mt-[10%] tablet:mt-[7%]
    //           laptop-l:mt-[5%]
    //           laptop-s:mt-[5%]
    //           p-1 tablet:w-[400px]"
    //             >
    //               <PhoneInput
    //                 enableSearch={true}
    //                 countryCodeEditable={false}
    //                 inputStyle={{
    //                   width: "100%",
    //                   height: "40px",
    //                 }}
    //                 country="ae"
    //                 value={formik.values.mobileNumber}
    //                 onChange={(phone, country) => {
    //                   formik.setFieldValue("mobileNumber", phone);
    //                   setCountryName(country.name);
    //                 }}
    //                 name="mobileNumber"
    //               />
    //               {formik.touched.mobileNumber &&
    //                 formik.errors.mobileNumber && (
    //                   <span className="text-[#EF4444] mt-1 mobile-s:text-[13px]">
    //                     {formik.errors.mobileNumber}
    //                   </span>
    //                 )}
    //             </div>

    //             <div className="w-[100%] h-[45px] rounded-[5px] mt-[10%] tablet:mt-[7%] p-1 tablet:w-[400px]  laptop-s:mt-[5%] laptop-l:mt-[5%]">
    //               <Input
    //                 type="text"
    //                 placeholder="Email"
    //                 className=" text-gray-950 p-2 w-[100%] rounded-[5px]
    //                  border border-solid border-[#CACACA] text-[15px] outline-none input-focused"
    //                 id="email"
    //                 name="email"
    //                 value={formik.values.email.toLowerCase()}
    //                 onChange={(e) => {
    //                   formik.handleChange(e);
    //                   handleFieldChange();
    //                 }}
    //                 onBlur={formik.handleBlur}
    //                 autocomplete="off"
    //               />
    //               {formik.touched.email && formik.errors.email && (
    //                 <span className=" w-[40%] text-[#EF4444] mobile-s:text-[13px]">
    //                   {formik.errors.email}
    //                 </span>
    //               )}
    //             </div>
    //           </div>
    //           <div className="flex mobile-s:block tablet:flex gap-4">
    //             <div className="w-[100%] h-[45px] rounded-[5px]  mt-[10%] tablet:mt-[7%]  p-1 tablet:w-[400px] laptop-l:mt-[4%] laptop-s:mt-[5%]">
    //               {/* <div className="flex items-center border border-solid border-[#CACACA] rounded-md ">
    //                 <Input
    //                   type={`${showPassword ? "password" : "text"}`}
    //                   placeholder="Password"
    //                   className="text-gray-950 p-2 w-[100%] rounded-[5px] text-[15px] outline-none input-focused"
    //                   id="password"
    //                   name="password"
    //                   value={formik.values.password}
    //                   onChange={(e) => {
    //                     formik.handleChange(e);
    //                     handleFieldChange();
    //                   }}
    //                   onBlur={formik.handleBlur}
    //                   autoComplete="off"
    //                   onPaste={(e) => {
    //                     e.preventDefault(); // Prevent pasting
    //                   }}
    //                   onSelectStart={(e) => e.preventDefault()} // Prevent text selection on start
    //                 />
    //                 <button
    //                   type="button"
    //                   className="p-2" // Adding padding for easier click
    //                   onClick={togglePasswordVisibility} // Toggle visibility on click
    //                 >
    //                   {showPassword ? <FaEyeSlash /> : <FaEye />}
    //                 </button>
    //               </div> */}

    //               <div className="relative w-full h-[42px]">
    //                 <Input
    //                   type={`${showPassword ? "password" : "text"}`}
    //                   placeholder="New Password"
    //                   className="text-gray-950 p-2 text-[15px] outline-none w-full rounded-[5px] border border-solid h-full border-[#CACACA] input-focused"
    //                   id="password"
    //                   name="password"
    //                   value={formik.values.password}
    //                   onChange={(e) => {
    //                     formik.handleChange(e);
    //                     handleFieldChange();
    //                   }}
    //                   onBlur={formik.handleBlur}
    //                   autoComplete="off"
    //                   onPaste={(e) => {
    //                     e.preventDefault(); // Prevent pasting
    //                   }}
    //                   onSelectStart={(e) => e.preventDefault()} // Prevent text selection on start
    //                 />
    //                 <button
    //                   type="button"
    //                   className="absolute top-1/2 right-3 transform -translate-y-1/2"
    //                   onClick={togglePasswordVisibility} // Toggle visibility on click
    //                 >
    //                   {showPassword ? <FaEyeSlash /> : <FaEye />}
    //                 </button>
    //               </div>
    //               {formik.touched.password && formik.errors.password && (
    //                 <span className="text-[#EF4444] mobile-s:text-[13px]">
    //                   {formik.errors.password}
    //                 </span>
    //               )}
    //             </div>
    //             <div
    //               className={`w-[100%] h-[42px] rounded-[5px]  ${
    //                 formik.touched.password &&
    //                 formik.errors.password &&
    //                 formik.values.password
    //                   ? "mobile-s:mt-[40%] mobile-m:!mt-[25%] mobile-l:!mt-[20%] tablet:!mt-[7%] laptop-s:!mt-[5%] laptop-l:!mt-[4%]"
    //                   : "mt-[10%]"
    //               } tablet:mt-[7%]  p-1 tablet:w-[400px] laptop-l:mt-[4%] laptop-s:mt-[5%]`}
    //             >
    //               {/* <div className="flex items-center border border-solid border-[#CACACA] rounded-md">
    //                 <Input
    //                   type={`${confrimShowPassword ? "password" : "text"}`}
    //                   placeholder="Confirm Password"
    //                   className="text-gray-950 p-2 w-[100%] rounded-[5px] text-[15px] outline-none input-focused"
    //                   id="confirmPassword"
    //                   name="confirmPassword"
    //                   value={formik.values.confirmPassword}
    //                   onChange={(e) => {
    //                     formik.handleChange(e);
    //                     handleFieldChange(); // Clear server error on field change
    //                   }}
    //                   onBlur={formik.handleBlur}
    //                   autocomplete="off" // Disable auto-suggestion
    //                   onPaste={(e) => {
    //                     e.preventDefault(); // Prevent pasting
    //                   }}
    //                   onSelectStart={(e) => e.preventDefault()} // Prevent text selection on start
    //                 />
    //                 <button
    //                   type="button"
    //                   className="p-2" // Adding padding for easier click
    //                   onClick={toggleConfrimPasswordVisibility} // Toggle visibility on click
    //                 >
    //                   {confrimShowPassword ? <FaEyeSlash /> : <FaEye />}
    //                 </button>
    //               </div> */}

    //               <div className="relative w-full h-[42px]">
    //                 <Input
    //                   type={`${confrimShowPassword ? "password" : "text"}`}
    //                   placeholder="Confirm Password"
    //                   className="text-gray-950 p-2 text-[15px] outline-none w-full rounded-[5px] border border-solid h-full border-[#CACACA] input-focused"
    //                   id="confirmPassword"
    //                   name="confirmPassword"
    //                   value={formik.values.confirmPassword}
    //                   onChange={(e) => {
    //                     formik.handleChange(e);
    //                     handleFieldChange(); // Clear server error on field change
    //                   }}
    //                   onBlur={formik.handleBlur}
    //                   autocomplete="off" // Disable auto-suggestion
    //                   autoComplete="off"
    //                   onPaste={(e) => {
    //                     e.preventDefault(); // Prevent pasting
    //                   }}
    //                   onSelectStart={(e) => e.preventDefault()} // Prevent text selection on start
    //                 />
    //                 <button
    //                   type="button"
    //                   className="absolute top-1/2 right-3 transform -translate-y-1/2"
    //                   onClick={toggleConfrimPasswordVisibility} // Toggle visibility on click
    //                 >
    //                   {confrimShowPassword ? <FaEyeSlash /> : <FaEye />}
    //                 </button>
    //               </div>
    //               {formik.touched.confirmPassword &&
    //                 formik.errors.confirmPassword && (
    //                   <span className=" text-[#EF4444] mt-[2%] mobile-s:text-[13px]">
    //                     {formik.errors.confirmPassword}
    //                   </span>
    //                 )}
    //             </div>
    //           </div>
    //           <div
    //             className={`mt-5 ${
    //               formik.touched.password &&
    //               formik.errors.password &&
    //               formik.values.password
    //                 ? "mt-[13%]"
    //                 : null
    //             }`}
    //           >
    //             <Button
    //               className="bg-blue-500 hover:bg-blue-700 mobile-s:px-6
    //             mobile-s:py-4  mobile-s:text-[12px] mobile-m:text-[12px] tablet:text-[16px] mobile-m:!px-8 mobile-m:!py-4 text-[#FFFFFF] font-bold rounded-[7px] focus:outline-none
    //           focus:shadow-outline mt-[4%] mb-4"
    //               type="submit"
    //             >
    //               Create account
    //             </Button>

    //             <Button
    //               className="bg-gray-500 hover:bg-gray-700 ml-4 mobile-s:px-6
    //             mobile-s:py-4  mobile-s:text-[12px] mobile-m:text-[12px] tablet:text-[16px] mobile-m:!px-8 mobile-m:!py-4 text-[#FFFFFF] font-bold rounded-[7px] focus:outline-none focus:shadow-outline mt-[4%] mb-4"
    //               type="button"
    //               onClick={() => navigate("/login")}
    //             >
    //               Cancel
    //             </Button>
    //           </div>
    //         </form>
    //       </div>
    //     </Card>
    //   </div>
    // </AuthLayout>
    <AuthLayout>
      <div className="overflow-auto mobile-s:mt-[5%] tablet:mt-[0%] tablet:overflow-hidden w-full">
        <Card
          className={`m-auto py-[3%] px-[4%] font-[inter]
    mobile-s:mt-[12%] mobile-m:mt-[25%]  mobile-l:mt-[25%] tablet:!mx-auto tablet:!my-auto
     mobile-l:mb-[20%] mobile-s:!mb-[20%] mobile-m:mb-[20%]
      mobile-s:w-[90%] bg-[#1a202c] shadow-md
    rounded-[12px] laptop-l:w-[30%] laptop-min:w-[55%] laptop-s:w-[65%] 
    tablet:w-[80%] desktop:w-[40%] flex flex-col justify-center over`}
          style={{
            maxHeight: "100vh", // Set the max height to the viewport height
          }}
        >
          <div className="flex items-center justify-center flex-col">
            <div style={{ height: "70px", width: "70px" }}>
              {" "}
              <img src={Info} alt="info" />
            </div>
            <p className="mobile-s:text-[18px] mobile-m:text-[18px] mobile-l:!text-[24px] text-blue-600">
              New User Registration Pause
            </p>
            <p className="mobile-s:text-[16px] mobile-m:text-[16px] mobile-l:![18px] mt-2 text-white text-justify">
              We have temporarily paused new user registrations as we focus on
              enhancing and improving our platform to provide you with an even
              better experience. Thank you for your patience and understanding.
            </p>
            <button
              onClick={() => navigate("/home")}
              className="bg-blue-600 w-[100%] h-[45px] text-white text-[18px] mt-4"
            >
              OK
            </button>
          </div>
        </Card>
      </div>
    </AuthLayout>
  );
}

export default Sinup;

import axios from "axios";

export let wsURL = process.env.REACT_APP_TEST_WS_URL;
export let baseURL = process.env.REACT_APP_TEST_BASE_URL;

const axiosClient = axios.create({
  baseURL: baseURL,
  headers: {
    Accept: "application/json",
    "Content-Type": "application/json",
  },
});

function someFunctionToCalculateSing(data) { 
  return data?.checkHeader || null;
}

// Request interceptor
axiosClient.interceptors.request.use(
  (config) => {
    const accessToken = JSON.parse(localStorage.getItem("token"));
    if (accessToken) {
      config.headers["Authorization"] = "Bearer " + accessToken;
    }
    const checkHeader = someFunctionToCalculateSing(config.data);

    if (checkHeader) {
      config.headers["Sign"] = checkHeader;
      delete config.data.checkHeader;
    }

    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

// Response interceptor
axiosClient.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    return Promise.reject(error);
  }
);

export default axiosClient;

import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import PlayerLayout from "../PlayerLayout/PlayerLayout";
import Standing from "../ViewDetails/Standing/Standing";
import Picks from "../ViewDetails/Picks/Picks";
import TeamDetailHOC from "../TeamDetailHOC/TeamDetailHOC";
import VotePopUp from "../Models/VotePopUp";
import { jackpotTableHeader } from "../TableHeader/TableHeader";

import newBackArrow from "../../assets/card/new-back-arrow.svg";
import PotDetail from "../MyPotViewDetail/PotDetail";
import { fetchPotInfoJackPotView } from "../../services/jackPotView/jackPotView";

import { useLocation } from "react-router-dom";
import { formatAsIndianCurrency } from "../../utils/currencyFormat";

function JackPotViewDetail({ isMobile, isLaptop, currencyType }) {
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [isOpen, setIsOpen] = useState();
  const [league, setLeague] = useState("");
  const [fetchPotInfo, setFetchPotInfo] = useState([]);

  useEffect(() => {
    fetchJackPotInfo();
  }, []);

  const isVoteNow = useSelector((state) => {
    return state.jackPot.isVoteNow;
  });

  useEffect(() => {
    setIsOpen(isVoteNow);
  }, [isVoteNow]);

  const backBtnHandler = () => {
    navigate("/dashboard");
  };

  const data = location.state;
  const { potId, pickId, seasonId, matchWeekId: matchWeek, potType } = data;

  const fetchJackPotInfo = async () => {
    const res = await dispatch(fetchPotInfoJackPotView(`/pot/pots/${potId}`));
    if (res.status === "Success") {
      let adminFee = (res?.data?.max_pot_value / 100) * res?.data?.admin_fee;
      let obj = {
        pot_id: res.data.id,
        pick_id: pickId,
        category: res.data.category,
        matchWeek: res?.data?.match_weeks?.current_match_week,
        potValue: formatAsIndianCurrency(
          res?.data?.max_pot_value - adminFee,
          res?.data?.pot_currency
        ),
        no_of_picks: res?.data?.joined_picks || 0,
        pick_status: res?.data?.status,
        max_pot_value: res?.data?.max_pot_value,
        admin_fee: res?.data?.admin_fee,
      };
      setFetchPotInfo([obj]);
      setLeague(res?.data?.league);
    }
  };

  return (
    <PlayerLayout>
      <div className="bg-[#203725] p-4 sticky top-0 mt-6 z-30">
        <nav class="flex ml-[10%]" aria-label="Breadcrumb">
          <img
            src={newBackArrow}
            className="h-5 w-5 cursor-pointer"
            onClick={backBtnHandler}
          />
          <ol class="inline-flex ml-5 items-center space-x-1 md:space-x-2 rtl:space-x-reverse">
            <li class="inline-flex text-[#FFFFFF] items-center">Split Round</li>
            <li className="text-[#FFFFFF]">
              <div class="flex items-center">/{league}</div>
            </li>
          </ol>
        </nav>
      </div>
      <div className="mb-[5%]">
        <PotDetail fetchPotInfo={fetchPotInfo} />
        {isLaptop ? (
          <div className="flex px-[1.5%] h-[100%] mb-10 overflow-hidden">
            <Picks isMobile={isMobile} potType="jackPot" />
            <Standing isMobile={isMobile} potType="jackPot" />
          </div>
        ) : (
          <>
            <Picks isMobile={isMobile} potType="jackPot" />
            <Standing isMobile={isMobile} potType="jackPot" />
          </>
        )}
      </div>
      {isOpen ? <VotePopUp /> : null}
    </PlayerLayout>
  );
}

export default TeamDetailHOC(JackPotViewDetail);

import { createSlice } from "@reduxjs/toolkit";
import { parse } from "postcss";

const myPotSlice = createSlice({
  name: "myPot",
  initialState: {
    loading: false,
    viewPotInfo: {},
    currentMatchWeek: 0,
    matchWeeks: [],
    data: [],
    playerPicks: [],
    standings: [],
    otherPlayesStanding: [],
    error: "",
    jackPotOtherPlayerPrediction: [],
    confirmPopUp: false,
    selectedTeamDetails: {
      pot: "",
      pickId: "",
      matchId: "",
      prediction: "",
      team: "",
      userAction: "",
    },
    pagination: {
      pageSize: 10,
      currentPage: 1,
      totalRecords: 0,
      next: true,
    },
  },
  reducers: {
    updateOtherPlayerPagination: (state, action) => {
      state.pagination = {
        ...state.pagination,
        ["currentPage"]: action.payload.page,
      };
    },
    fetchMyPotStart: (state) => {
      state.loading = true;
      state.error = null;
    },
    closeMyPotChoosePickPopup: (state) => {
      state.loading = false;
    },
    fetchPotInfo: (state, action) => {
      state.loading = false;
      state.viewPotInfo = action.payload;
    },
    fetchAllMatchWeek: (state, action) => {
      state.loading = false;
      state.matchWeeks = action.payload;
    },
    selectedCurrentMatchWeek: (state, action) => {
      state.currentMatchWeek = action.payload;
    },
    fetchOtherStandingDataSucc: (state, action) => {
      state.otherPlayesStanding = action.payload;
    },
    fetchMyPickWeek: (state, action) => {
      state.loading = false;
      state.playerPicks = action.payload;
    },
    fetchStandingDataSucc: (state, action) => {
      state.loading = false;
      state.standings = action.payload;
    },
    fetchMyPotSuccess: (state, action) => {
      state.loading = false;
      state.data = action.payload;
    },
    fetchMyPotFailure: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },
    jackpotOtherPlayerPrediction: (state, action) => {
      state.loading = false;
      state.jackPotOtherPlayerPrediction = [
        ...state.jackPotOtherPlayerPrediction,
        ...action.payload.data,
      ];
      state.pagination = {
        ...state.pagination,
        totalRecords: action.payload.total,
        next: action.payload.next,
      };
    },
    confirmPopUpChange: (state, action) => {
      state.confirmPopUp = action.payload;
    },
    updateSelectMatch: (state, action) => {
      state.selectedTeamDetails = {
        pot: action.payload.pot,
        pickId: action.payload.pickId,
        matchId: action.payload.matchId,
        prediction: action.payload.prediction,
        team: action.payload.team,
        userAction: action.payload.userAction,
      };
    },
    updatemodifiedFixture: (state, action) => {
      const { fixture, pick, pot, prediction, team } =
        action?.payload?.fixtureInfo;
      state.confirmPopUp = false;
      let getMatchData =
        state?.playerPicks.length > 0
          ? JSON.parse(JSON.stringify(state?.playerPicks))
          : [];
      let teamType = team === "hometeam" ? "home" : "away";
      const changePlayerMatchData = getMatchData.map((ele) => {
        if (ele.id === fixture) {
          ele.short.forEach((item) => {
            if (item.team_type === teamType) {
              item["predicted"] = true;
            } else {
              item.predicted = false;
            }
          });
        } else {
          ele.short.forEach((item) => {
            item.predicted = false;
          });
        }
        return ele;
      });

      state.playerPicks = changePlayerMatchData;
    },
    resetMatchWeek: (state, action) => {
      state.playerPicks = [];
      state.matchWeeks = [];
    },
    resetJackPotOtherPlayersPicks:(state, action)=>{
      state.loading = false;
      state.jackPotOtherPlayerPrediction = [];
      state.pagination = {
        ...state.pagination,
        currentPage: 1,
        next: true,
      };
    }
  },
});

export const {
  confirmPopUpChange,
  resetMatchWeek,
  fetchMyPotStart,
  closeMyPotChoosePickPopup,
  fetchPotInfo,
  fetchAllMatchWeek,
  fetchMyPickWeek,
  fetchMyPotSuccess,
  fetchMyPotFailure,
  selectedCurrentMatchWeek,
  fetchStandingDataSucc,
  fetchOtherStandingDataSucc,
  jackpotOtherPlayerPrediction,
  updateSelectMatch,
  updatemodifiedFixture,
  updateOtherPlayerPagination,
  jackpotOtherPlayerPredictionReset,
  resetJackPotOtherPlayersPicks
} = myPotSlice.actions;
export default myPotSlice.reducer;

import React, { useEffect, useState, useRef } from "react";
import "./pagination.css";
import { useSelector, useDispatch } from "react-redux";
import { updateOtherPlayerPagination } from "../MyPotViewDetail/myPot.slice";
import { RotatingLines } from "react-loader-spinner";

function Table({
  tableName,
  className,
  tableHeader,
  tableBody,
  component,
  staticMessage,
}) {
  const dispatch = useDispatch();

  let [myPotPagination, setMyPotPagination] = useState({
    totalRecord: 0,
    pageSize: 2,
    currentPage: 1,
    next: true,
  });

  const paginationDetail = useSelector((state) => state.myPot.pagination);

  useEffect(() => {
    setMyPotPagination((prev) => ({
      ...prev,
      totalRecord: paginationDetail.totalRecord,
      pageSize: paginationDetail.pageSize,
      currentPage: paginationDetail.currentPage,
      next: paginationDetail.next,
    }));
  }, [
    paginationDetail.totalRecord,
    paginationDetail.pageSize,
    paginationDetail.currentPage,
    paginationDetail.next,
  ]);

  const paginationData = useSelector((state) => state.myPicks.pagination);

  const myPototherPlayesStanding = useSelector(
    (state) => state.myPot.jackPotOtherPlayerPrediction
  );

  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    // setCurrentPage(paginationData.currentPage);
    // setPageSize(paginationData.pageSize);
  }, [paginationData.pageSize, paginationData.currentPage]);

  useEffect(() => {
    if (tableName === "otherUser") {
      const delayTimeout = setTimeout(() => {
        setIsLoading(false);
      }, 1000);

      return () => clearTimeout(delayTimeout);
    } else {
      setIsLoading(false);
    }
  }, [tableName]);

  function isImage(str) {
    try {
      new URL(str);
      return true;
    } catch (error) {
      return false;
    }
  }

  // observer

  const [hasMore, setHasMore] = useState(true);

  const observerTarget = useRef(null);

  useEffect(() => {
    if (tableName === "otherUserPicks") {
      const observer = new IntersectionObserver(
        (entries) => {
          const [entry] = entries;

          let pageNum =
            paginationDetail.pageSize > 0
              ? Math.ceil(
                  paginationDetail.totalRecords / paginationDetail.pageSize
                )
              : 0;
          if (entry.isIntersecting && hasMore && !isLoading) {
            setMyPotPagination((prev) => {
              const updatedPage = prev.currentPage + 1;
              if (updatedPage <= pageNum) {
                dispatch(
                  updateOtherPlayerPagination({
                    page: updatedPage,
                    pageSize: 10,
                  })
                );
              }
              return {
                ...prev,
                currentPage: updatedPage,
              };
            });
          }
        },
        {
          root: null,
          rootMargin: "0px",
          threshold: 1,
        }
      );

      if (observerTarget.current) {
        observer.observe(observerTarget.current);
      }

      return () => {
        if (observerTarget.current) {
          observer.unobserve(observerTarget.current);
        }
      };
    }
  }, [isLoading, hasMore]);

  return (
    <>
      {isLoading ? (
       <div className="flex justify-center mx-auto">
       <RotatingLines
         visible={true}
         height="96"
         width="96"
         color="grey"
         strokeWidth="5"
         animationDuration="0.75"
         ariaLabel="rotating-lines-loading"
         wrapperStyle={{}}
         wrapperClass=""
       />
     </div>
      ) : (
        <div className="overflow-x-auto">
          <table className={`${className}`}>
            <thead
              className={`header-container ${
                component === "activeTeam" ? "laptop-s:!h-[70px]" : ""
              } laptop-s:!h-[50px] h-16 p-3 bg-[#00AB26] text-white
               rounded-bl-6 rounded-br-6 rounded-tl-[6px] rounded-tr-[6px]`}
            >
              <tr className="w-[150px]">
                {tableHeader.map((head) => (
                  <th
                    key={head.id}
                    className={`text-[#F8F8F8] w-[250px] ${
                      component === "activeTeam" ? "p-3" : "p-5"
                    } text-nowrap`}
                  >
                    {head.cellName.charAt(0).toUpperCase() +
                      head.cellName.slice(1)}
                  </th>
                ))}
              </tr>
            </thead>
            <tbody className={`${tableName === "otherUser" ? "" : null}`}>
              {tableBody?.length > 0 ? (
                tableBody?.map((rowData, rowIndex) => {
                  if (rowData) {
                    return (
                      <tr key={rowIndex} className="text-center w-[150px] p-2">
                        {Object.entries(rowData).map(
                          ([key, cellData], cellIndex) => {
                            let systemPrediction;
                            let prediction;
                            if (
                              (tableName === "otherUserPicks" ||
                                tableName === "otherUser") &&
                              key !== "player"
                            ) {
                              systemPrediction = cellData.system;
                              prediction = cellData.pick_result;
                              cellData = cellData?.logo
                                ? cellData?.logo
                                : cellData;
                            }
                            return (
                              <td
                                key={cellIndex}
                                className={`text-nowrap ${
                                  tableName === "otherUserPicks"
                                    ? "border-b border-gray-300"
                                    : null
                                } ${
                                  component === "myPicksMenu" ||
                                  component === "historyPicks"
                                    ? ""
                                    : "p-2"
                                } ${
                                  key === "action" ? "w-[250px]" : "w-[10%]"
                                }`}
                              >
                                {cellData ? (
                                  isImage(cellData) ? (
                                    <div className="flex items-center justify-center">
                                      <img
                                        src={cellData}
                                        alt="image"
                                        className={`flex h-10 w-10 p-1 ${
                                          systemPrediction &&
                                          prediction === "Win"
                                            ? "bg-green-500"
                                            : systemPrediction &&
                                              prediction === "Lost"
                                            ? "bg-red-500"
                                            : systemPrediction &&
                                              prediction == null
                                            ? "bg-blue-500"
                                            : prediction === "Lost"
                                            ? "bg-red-500"
                                            : null
                                        }`}
                                      />
                                    </div>
                                  ) : typeof cellData === "object" &&
                                    cellData !== null ? (
                                    cellData
                                  ) : (
                                    <div
                                      className={`${
                                        key === "vote" && cellData === "Yes"
                                          ? "text-green-500 font-semibold text-[15px]"
                                          : key === "vote" && cellData === "No"
                                          ? "text-red-500 font-semibold text-[15px]"
                                          : ""
                                      } justify-start`}
                                    >
                                      {cellData}
                                    </div>
                                  )
                                ) : (
                                  <>
                                    {tableName === "otherUserPicks" ||
                                    tableName === "otherUser" ? (
                                      <p className="text-red-500">X</p>
                                    ) : (
                                      "-"
                                    )}
                                  </>
                                )}
                              </td>
                            );
                          }
                        )}
                      </tr>
                    );
                  }
                })
              ) : (
                <tr>
                  <td colSpan={9} className="text-center">
                    {staticMessage ? staticMessage : "No data found"}
                  </td>
                </tr>
              )}
            </tbody>
            <div ref={observerTarget}></div>
            {!hasMore && <p>No more data to load</p>}
          </table>
        </div>
      )}
    </>
  );
}

export default Table;

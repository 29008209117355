import React, { useEffect, useState } from "react";
import PieChart from "./Piechart";
import useFindDevice from "../../CustomHooks/FindDevice";
import { useSelector, useDispatch } from "react-redux";
import {
  fechPieChatMatchWeeks,
  fetchChatDetail,
} from "../../../services/dashboard/teams";

import noPickSummarry from "../../../assets/card/no-pick-summary.svg";
import { useLocation } from "react-router-dom";
import { RotatingLines } from "react-loader-spinner";

function Chart() {
  const colors = process.env.REACT_APP_COLORS
    ? JSON.parse(process.env.REACT_APP_COLORS)
    : ["#CFFF92", "#007FFF", "#00387A", "#FFD600", "#00C05B"];
  const location = useLocation();
  // const [, , , , potId, pickId, seasonId, matchWeekId, potType] =
  //   window.location.href.split("/"); //

  const data = location.state;
  const { potId, pickId, seasonId, matchWeekId, potType } = data;

  const dispatch = useDispatch();
  const [chartMatchWeek, setChatMatchWeek] = useState([]);
  const [selectMatchWeek, setSelectMatchWeek] = useState("");

  const [predictionTeams, setPredictionTeam] = useState({});

  useEffect(() => {
    fetchChartMatchWeek();
  }, []);

  const fetchChartMatchWeek = async function () {
    try {
      const res = await dispatch(
        fechPieChatMatchWeeks(`/pot/matchweeks-dropdown?pot=${potId}`)
      );
      setChatMatchWeek(res.data);
      let data = res.data[res.data.length - 1];
      setSelectMatchWeek(data);
    } catch (e) {
      console.log(e);
    }
  };

  useEffect(() => {
    if (selectMatchWeek) {
      fetchChartInfo();
    }
  }, [selectMatchWeek]);

  const fetchChartInfo = async () => {
    const res = await dispatch(
      fetchChatDetail(
        `/pot/picksummary?matchweek=${selectMatchWeek}&pot=${potId}`
      )
    );
    setPredictionTeam(res);
  };
  let isChartVisiable = predictionTeams?.top_5?.length > 0 ? true : false;

  const isRemainingPicks =
    predictionTeams?.others?.length > 0 > 0 &&
    predictionTeams?.others.find((ele) => {
      if (ele.prediction_count > 0) {
        return true;
      }
    });

  return (
    <div className="flex w-[100%] gap-2 h-full p-2 rounded-[6px] ">
      <div className="flex mobile-s:flex-col tablet:flex-row w-full gap-2 p-2 rounded-[6px]">
        {predictionTeams?.top_5 ? (
          <>
            <div
              className={`flex flex-col mobile-s:w-full mx-auto ${
                isChartVisiable ? "tablet:w-[70%]" : "tablet:w-[100%]"
              } bg-[#F7F7F7] border border-[#70778299] rounded-[6px]`}
            >
              {/* {potType === "newPot" ? null : ( */}
              <>
                <div className="flex flex-col text-center pt-2">
                  <div className="flex w-full mobile-s:flex-col  items-center px-6">
                    <div className="form-group ">
                      <select
                        onChange={(event) => {
                          const selectedValue = event.target.value;
                          setSelectMatchWeek(selectedValue);
                        }}
                        className="form-control font-interFont text-[500] mobile-m:text-[14px] laptop-min-s:text-[12px] laptop-min:text-[14px] border text-gray-950 border-gray-400 rounded-md p-2"
                        defaultValue={selectMatchWeek}
                      >
                        {chartMatchWeek?.length > 0 ? (
                          chartMatchWeek?.map((ele) => (
                            <option
                              key={ele}
                              value={ele}
                              className="text-gray-950 p-2 mobile-s:text-[14px] mobile-m:text-[14px] text-[500] laptop-min-s:text-[12px] laptop-min:text-[14px] font-interFont laptop-l:text-[16px]"
                              style={{ height: "120px" }}
                              selected
                            >
                              Match Week {ele?.split("-")[1]}
                            </option>
                          ))
                        ) : (
                          <option
                            className="text-gray-950 p-2"
                            style={{ height: "120px" }}
                          >
                            No Match Week available
                          </option>
                        )}
                      </select>
                    </div>
                  </div>
                </div>
                <hr className="w-[90%] border tablet:mt-[10px] laptop-min-s:mt-[10px] laptop-s:mt-[10px] border-[#70778299] mx-auto my-5" />
              </>

              {isChartVisiable ? (
                <div className="py-6 ">
                  <PieChart predictionTeams={predictionTeams} />
                </div>
              ) : (
                <div className="flex justify-center my-auto">
                  <div className="flex justify-center items-center flex-col gap-2 mx-auto">
                    <img src={noPickSummarry} className="h-12 w-12" />
                    <div className="flex justify-center mx-auto">
                      <p className="laptop-min-s:mx-4 text-center">
                        {potType === "newPot"
                          ? "Picks Summary for the current match week will be displayed only after the deadline"
                          : "Picks Summary for the current match week will be displayed only after the deadline"}
                      </p>
                    </div>
                  </div>
                </div>
              )}

              {isChartVisiable && (
                <>
                  <hr className="w-[90%] border border-[#70778299] mx-auto my-2" />
                  <div className="w-full flex py-6">
                    <div className="w-full flex mobile-s:flex-col gap-2 mobile-s:gap-4 tablet:flex-row  justify-between laptop-min: px-10">
                      <div className="flex mobile-s:justify-center  laptop-min:w-[30%] gap-4">
                        <h2 className="text-nowrap mobile-s:text-[16px] laptop-min:text-[14px] mobile-m:text-[18px]">
                          Top {predictionTeams?.top_5?.length} Active Picks
                        </h2>
                      </div>

                      <div className="flex flex-col mobile-s:ml-6 tablet:ml-0 gap-4 laptop-min:w-[70%]">
                        {predictionTeams?.top_5?.length > 0 ? (
                          predictionTeams?.top_5?.map((ele, i) => {
                            return (
                              <div
                                className="grid grid-flow-col items-center gap-2 mx-auto w-full max-w-xs md:max-w-none"
                                style={{
                                  gridTemplateColumns: "10% 10% 2fr",
                                  gap: "5%",
                                }}
                              >
                                <div className="flex justify-center">
                                  <div
                                    className="h-[10px] w-[10px] rounded-sm"
                                    style={{ backgroundColor: colors[i] }}
                                  ></div>
                                </div>

                                <div className="">
                                  <img
                                    src={ele.logo}
                                    className="h-[23px] w-[23px]"
                                    alt={
                                      ele?.team_name
                                        ? ele?.team_name
                                        : "team name"
                                    }
                                  />
                                </div>
                                <div className=" flex justify-start">
                                  <p className="truncate text-[14px]">
                                    {ele.team_name}
                                  </p>
                                </div>
                              </div>
                            );
                          })
                        ) : (
                          <div className="text-center">No Team there</div>
                        )}
                      </div>
                    </div>
                  </div>
                </>
              )}
            </div>
            {isChartVisiable && (
              <div className="relative flex mobile-s:w-full tablet:w-[30%] bg-[#F7F7F7] border border-[#70778299] rounded-[6px] justify-center">
                <div className="flex flex-col gap-4 p-2  w-full">
                  <div className="flex w-full pt-2 justify-between text-center">
                    {isRemainingPicks && (
                      <h2 className="text-nowrap mx-auto mobile-s:text-[16px] laptop-min:text-[14px] mobile-m:text-[18px]">
                        Remaining Picks
                      </h2>
                    )}
                  </div>
                  {isRemainingPicks && (
                    <hr
                      className={`w-[90%] border ${
                        isRemainingPicks && ""
                      } border-[#70778299] mx-auto`}
                    />
                  )}
                  <div>
                    {isRemainingPicks && (
                      <>
                        {predictionTeams?.others?.length > 0 ? (
                          predictionTeams?.others?.map((ele) => {
                            if (ele.prediction_count > 0) {
                              return (
                                <div>
                                  <div
                                    className="flex w-full justify-between items-center gap-4"
                                    key={ele.prediction_count}
                                  >
                                    <div className="flex gap-2 mt-2 items-center flex-1 justify-center ml-2">
                                      <img
                                        src={ele.logo}
                                        className="h-[23px] w-[23px]"
                                        alt={ele.prediction_count}
                                      />
                                    </div>
                                    <div className="flex-1 text-center">
                                      <p className="text-[14px]">
                                        {ele.prediction_count}
                                      </p>
                                    </div>
                                  </div>
                                </div>
                              );
                            }
                          })
                        ) : (
                          <div className="text-center">No Team there</div>
                        )}
                      </>
                    )}
                    <div className={`${isRemainingPicks ? "mt-8" : ""} `}>
                      {isRemainingPicks && (
                        <hr className="w-[90%] mx-auto border border-[#70778299]" />
                      )}

                      <div
                        className={`flex flex-col items-center my-auto ${
                          isRemainingPicks ? "mt-4" : ""
                        }  mb-2 gap-2 laptop-min-s:text-[13px] laptop-min:text-[14px]`}
                      >
                        <h2 className="text-nowrap mx-auto mobile-s:text-[16px] laptop-min:text-[14px] mobile-m:text-[18px]">
                          Default Picks
                        </h2>
                        {!isRemainingPicks && (
                          <hr className="w-full border border-[#70778299]" />
                        )}
                        <div className="flex w-full justify-between text-center">
                          <h2 className="flex-1 mobile-s:text-[14px] mt-2 text-[500] mobile-m:text-[14px] laptop-min-s:text-[12px]  laptop-min:text-[14px] laptop-l:text-[16px]">
                            Teams
                          </h2>
                          <h2 className="flex-1 mt-2 mobile-s:text-[14px] text-[500] mobile-m:text-[14px] laptop-min-s:text-[12px] laptop-min:text-[14px] laptop-l:text-[16px]">
                            Count
                          </h2>
                        </div>
                        {predictionTeams?.defaults_picks?.length > 0 ? (
                          predictionTeams.defaults_picks.map((ele, i) => {
                            return (
                              <div
                                key={ele.prediction_count}
                                className="flex w-full justify-between items-center gap-4 mt-2"
                              >
                                <div className="flex gap-2 items-center flex-1 justify-center ml-2">
                                  <img
                                    src={ele.logo}
                                    className="h-[23px] w-[23px]"
                                    alt={ele.prediction_count}
                                  />
                                </div>
                                <div className="flex-1 text-center">
                                  <p className="text-[14px]">
                                    {ele?.prediction_count}
                                  </p>
                                </div>
                              </div>
                            );
                          })
                        ) : (
                          <div className="text-center mt-2">
                            No Default Picks there
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )}
          </>
        ) : (
          <div className="flex justify-center mx-auto my-auto items-center">
            <RotatingLines
              visible={true}
              height="96"
              width="96"
              color="grey"
              strokeWidth="5"
              animationDuration="0.75"
              ariaLabel="rotating-lines-loading"
              wrapperStyle={{}}
              wrapperClass=""
            />
          </div>
        )}
      </div>
    </div>
  );
}

export default Chart;

import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import Button from "../../Elements/Button";
import { chooseNewPick, selectedPicks } from "./dashboard.slice";
import editBlack from "../../assets/card/edit-black.svg";
import { updateSelectedTeamPot } from "./team.slice";
import {
  fechSelectedPrevWeekTeam,
  fetchTeamForPick,
} from "../../services/dashboard/teams";
import choosePickImg from "../../assets/images/choosePickImg.svg";

import editIcon from "../../assets/images/myPotEditIcon.png";
import { jackpotVoteNowPopup } from "../JackPotViewDetail/jackpot.slice";
import { compareTime } from "../../utils/campareUtcTime";

import btnArrow from "../../assets/card/join-btn-arrow.svg";
import closeImg from "../../assets/card/close.svg";

import "./dashboard.css";

import edit from "../../assets/card/edit.svg";

import { resetMatchWeek } from "../MyPotViewDetail/myPot.slice";

export function NewPotButton({ pot, potType, pickId }) {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const dashboardData = useSelector((state) => {
    return state.dashboard;
  });
  function joinHandler(potId) {
    if (dashboardData?.data?.user?.id) {
      dispatch(
        selectedPicks({
          potId: potId,
          selectPick: true,
        })
      );
    }
  }

  const viewDetailHandler = () => {
    let data = {
      potId: pot.id,
      pickId: pickId,
      seasonId: pot.season,
      matchWeekId: pot.current_match_week_id,
      potType: "newPot",
    };
    dispatch(resetMatchWeek());
    navigate("/myRoundView", { state: data });
  };

  let isDisabled = pot?.max_pot_value == pot?.current_pot_value;

  return (
    <div>
      {!compareTime(pot?.last_entry_time) ? (
        <div className="flex gap-2">
          <Button
            className={`text-nowrap bg-[#009B36] text-[white] cursor-pointer flex items-center gap-[10px]
        rounded-[15px]
        laptop-l:p-5 laptop-l:py-3 laptop-s:p-3 tablet:p-3 mobile-s:p-3
        focus:outline-none focus:shadow-outline`}
          >
            <p
              className="mobile-s:text-[12px] mobile-m:!text-[14px] laptop-s:text-[12px] 
        laptop-l:text-[14px] font-lato text-[#FFFFFF7A]"
            >
              Entry Closed
            </p>

            <img src={closeImg} className="h-5 w-5" style={{ opacity: 0.5 }} />
          </Button>
          <Button
            className="border-2 border-[#CFFF92] cursor-pointer text-nowrap flex items-center gap-[10px]
         rounded-[15px] mobile-s:p-3
         laptop-s:p-3 laptop-l:p-5 laptop-l:py-3 laptop-min:p-3
         focus:outline-none focus:shadow-outline font-lato"
            onClick={viewDetailHandler}
          >
            <p className="flex mobile-s:text-[12px] font-lato mobile-m:!text-[14px] laptop-s:!text-[12px] laptop-min:!text-[14px]  laptop-l:!text-[14px] text-[#CFFF92]">
              View Fixtures
            </p>
          </Button>
        </div>
      ) : (
        <div className="flex gap-2">
          <Button
            className={`text-nowrap ${
              isDisabled && "opacity-50"
            } bg-[#00458B] hover:bg-[#00458B]}
               text-[white] cursor-pointer flex items-center gap-[10px]
        rounded-[15px]
        laptop-l:p-5 laptop-l:py-3 laptop-s:p-3 tablet:p-3 mobile-s:p-3
        focus:outline-none focus:shadow-outline`}
            onClick={() => {
              joinHandler(pot.id);
            }}
            disabled={isDisabled ? isDisabled : false}
          >
            <p
              className="mobile-s:text-[12px] mobile-m:!text-[14px] laptop-s:text-[12px] 
        laptop-l:text-[14px] font-lato"
            >
              Play Now
            </p>

            <img src={btnArrow} className="h-5 w-5" />
          </Button>
          <Button
            className="border-2 border-[#CFFF92] cursor-pointer text-nowrap flex items-center gap-[10px]
          rounded-[15px] mobile-s:p-3
          laptop-s:p-3 laptop-l:p-5 laptop-l:py-3 laptop-min:p-3
          focus:outline-none focus:shadow-outline font-lato"
            onClick={viewDetailHandler}
          >
            <p className="flex mobile-s:text-[12px] font-lato mobile-m:!text-[14px] laptop-s:!text-[12px] laptop-min:!text-[14px]  laptop-l:!text-[14px] text-[#CFFF92]">
              View Fixtures
            </p>
          </Button>
        </div>
      )}
    </div>
  );
}

export function ActivePotButton({
  pot,
  potType,
  choosedPick,
  pickId,
  logo,
  status,
  potStatusFromPot,
  systemPrediction,
}) {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [isTimeValid, setIsTimeValid] = useState(
    compareTime(pot?.dead_line?.dead_line)
  );

  useEffect(() => {
    const interval = setInterval(() => {
      setIsTimeValid(compareTime(pot?.dead_line?.dead_line));
    }, 1000);

    // Clean up the interval on component unmount
    return () => clearInterval(interval);
  }, [pot?.dead_line?.dead_line]);

  async function pickNewTeamHandler(pickBtnType, pot) {
    dispatch(chooseNewPick({ choosePick: true, pickBtnType: pickBtnType }));
    dispatch(updateSelectedTeamPot({ pot, pickId }));
    await dispatch(
      fechSelectedPrevWeekTeam(`/pot/activepredictions/${pickId}`)
    );
    dispatch(
      fetchTeamForPick(
        `/pot/fixtures?season=${pot.season_year}&league=${pot.season}&pot_id=${pot.id}&current_matchweek=true`
      )
    );
  }

  const viewDetailHandler = () => {
    let data = {
      potId: pot.id,
      pickId: pickId,
      seasonId: pot.season,
      matchWeekId: pot.current_match_week_id,
      potType: "myPot",
    };
    // dispatch(resetMatchWeek())
    navigate("/myRoundView", { state: data });
  };

  let potStatus;
  if (status === "Lost" || status === "Win" || pot.status === "Completed") {
    potStatus = true;
  } else {
    potStatus = false;
  }

  return (
    <div className="flex gap-5">
      {!compareTime(pot?.dead_line?.dead_line) || potStatus ? (
        <Button
          className={`${
            choosedPick.teamName === "NPY"
              ? "bg-[#00458B] hover:bg-[#00458B]"
              : null
          } laptop-s:w-[150px] cursor-pointer
          ${
            !compareTime(pot?.dead_line?.dead_line) || potStatus
              ? "opacity-[0.5] border-2 border-[#CFFF92] cursor-pointer text-nowrap"
              : "bg-[#00458B] hover:bg-[#00458B] text-white"
          } text-nowrap text-[#FFFFFF] flex items-center gap-[10px]
       rounded-[15px] mobile-s:p-3 
        laptop-min:px-3 laptop-l:px-5 laptop-l:py-3`}
          disabled={!compareTime(pot?.dead_line?.dead_line) || potStatus}
          onClick={() =>
            pickNewTeamHandler(
              choosedPick.teamName === "NPY" ? "choosePick" : "changePick",
              pot
            )
          }
          title="Choose a new team"
        >
          {logo[0].prediction?.logo && logo[0].prediction?.logo ? (
            <img
              src={logo[0].prediction?.logo.toString()}
              className="h-5 w-5"
            />
          ) : null}
          <p className="flex gap-2 items-center justify-center font-lato">
            <span
              className="mobile-s:text-[12px] mobile-m:!text-[14px] laptop-s:!text-[12px] 
          laptop-min:!text-[14px] laptop-l:text-[14px] tablet:text-[10px] font-lato"
            >
              {choosedPick.teamName === "NPY"
                ? "Choose Pick"
                : choosedPick.shortName}
            </span>{" "}
          </p>
          {choosedPick.teamName === "NPY" ? (
            <img
              src={choosePickImg}
              className="h-5 w-5 laptop-s:h-4 laptop-s:w-4 "
              style={{ color: "#00234B" }}
            />
          ) : (
            <>
              {" "}
              {status === "Lost" ||
                (status === "Completed" && (
                  <img
                    src={editIcon}
                    className="h-5 w-5 laptop-s:h-4 laptop-s:w-4"
                    style={{ color: "#00234B" }}
                  />
                ))}
            </>
          )}
        </Button>
      ) : (
        <Button
          className={`text-nowrap ${
            choosedPick.teamName === "NPY"
              ? "bg-[#00458B] hover:bg-[#00458B] blink-button text-[#FFFFFF] font-lato text-[16px]"
              : !isTimeValid
              ? "opacity-[0.5] border-2 border-[#CFFF92] cursor-pointer text-nowrap"
              : "bg-[#00458B] hover:bg-[#00458B] text-[#FFFFFF] font-lato text-[16px]"
          } cursor-pointer flex items-center gap-[10px]
        rounded-[15px]
        laptop-l:p-5 laptop-l:py-3 laptop-s:p-3 tablet:p-3 mobile-s:px-3 mobile-s:py-1
        focus:outline-none focus:shadow-outline`}
          onClick={() =>
            pickNewTeamHandler(
              choosedPick.teamName === "NPY" ? "choosePick" : "changePick",
              pot
            )
          }
          disabled={!isTimeValid}
        >
          {logo[0].prediction?.logo && logo[0].prediction?.logo ? (
            <img
              src={logo[0].prediction?.logo.toString()}
              className="h-5 w-5"
            />
          ) : null}
          <p className="flex gap-2 items-center justify-center font-lato">
            <span
              className="mobile-s:text-[12px] mobile-m:!text-[14px] laptop-s:!text-[12px] 
          laptop-min:!text-[14px] laptop-l:text-[14px] tablet:text-[10px] font-lato"
            >
              {choosedPick.teamName === "NPY"
                ? "Choose Pick"
                : choosedPick.shortName}
            </span>{" "}
          </p>
          {choosedPick.teamName === "NPY" ? (
            <img
              src={edit}
              className="mobile-s:h-4 mobile-s:w-4 tablet:h-8 tablet:w-8 laptop-s:h-6 laptop-s:w-6"
              style={{ color: "#00234B" }}
            />
          ) : (
            <img
              src={edit}
              className="mobile-s:h-4 mobile-s:w-4 tablet:h-8 tablet:w-8 laptop-s:h-6 laptop-s:w-6"
              style={{ color: "#00234B" }}
            />
          )}
        </Button>
      )}

      <Button
        className="border-2 border-[#CFFF92] cursor-pointer text-nowrap flex items-center gap-[10px]
         rounded-[15px] mobile-s:p-3
         laptop-s:p-3 laptop-l:p-5 laptop-l:py-3 laptop-min:p-3
         focus:outline-none focus:shadow-outline font-lato"
        onClick={viewDetailHandler}
        title="Choose a new team"
      >
        <p className="flex mobile-s:text-[12px] font-lato mobile-m:!text-[14px] laptop-s:!text-[12px] laptop-min:!text-[14px]  laptop-l:!text-[14px] text-[#CFFF92]">
          View Picks
        </p>

        {/* <img src={btnIicon} className="h-5 w-5 laptop-s:h-4 laptop-s:w-4 " /> */}
      </Button>
    </div>
  );
}

export function JackPotButton({ pot, potType, pickId }) {
  const [status, setStatus] = useState("");
  useEffect(() => {
    const vote =
      pot?.voted && pot?.voted?.find((vote) => vote?.pick_id === pickId);
    if (vote?.vote === "yes") {
      setStatus("YES");
    } else if (vote?.vote === "no") {
      setStatus("NO");
    } else {
      setStatus("");
    }
  }, [pot]);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const dashboardData = useSelector((state) => {
    return state.dashboard;
  });
  function joinHandler(potId) {
    if (dashboardData?.data?.user?.id) {
      dispatch(selectedPicks({ potId: potId, selectPick: true }));
    }
  }

  const JackPotViewDetail = (deadline) => {
    localStorage.setItem("jockPotDeadline", deadline);
    // navigate(`/prizePollView/${pot.pot}/${pickId}/${pot.season}`);
    // let data = {
    //   ${pot.pot}/${pickId}/${pot.season}
    // }
    let data = {
      potId: pot.pot,
      pickId: pickId,
      seasonId: pot.season,
    };
    navigate("/prizePollView", {
      state: data,
    });
  };

  const voteNowHandler = (deadline) => () => {
    if (compareTime(deadline)) {
      dispatch(
        jackpotVoteNowPopup({
          voteNowPopup: true,
          potId: pot.pot,
          pickId: pickId,
        })
      );
    }
  };

  return (
    <div className="flex gap-5">
      <Button
        className={`font-lato ${
          status === "YES"
            ? `bg-[#165235] hover:bg-[#165235] ${
                !compareTime(pot?.deadline) && "opacity-[0.5]"
              } text-[white]`
            : status === "NO"
            ? `bg-[#93272e] hover:bg-[#93272e] ${
                !compareTime(pot?.deadline) && "opacity-[0.7]"
              } text-[white]`
            : "bg-[#FFD600] blink-button hover:bg-[#FFD600] text-[#000000]"
        } cursor-pointer justify-center text-nowrap flex items-center gap-[10px]
                rounded-[15px] mobile-s:p-3
               laptop-s:p-3 laptop-l:w-[150px] laptop-l:py-3 laptop-min:p-3
                 focus:outline-none focus:shadow-outline`}
        onClick={voteNowHandler(pot?.deadline)}
      >
        <p
          className={`text-nowrap laptop-l:text-[14px] justify-center laptop-s:text-[12px]
           font-lato ${!compareTime(pot?.deadline)}`}
        >
          {status ? (
            `Voted: ${status}`
          ) : (
            <div className="flex items-center gap-2">
              <p className="font-500 font-lato">Vote Now</p>
            </div>
          )}
        </p>

        {pot?.voted?.length > 0 &&
        pot?.voted[0]?.vote &&
        !compareTime(pot?.deadline) ? (
          <img
            src={editIcon}
            className="h-5 w-5 laptop-s:h-4 laptop-s:w-4 "
            style={{ color: "#00234B" }}
          />
        ) : (
          compareTime(pot?.deadline) &&
          (status === "NO" ? (
            <img
              src={edit}
              className="mobile-s:h-4 mobile-s:w-4 tablet:h-8 tablet:w-8 laptop-s:h-6 laptop-s:w-6"
              style={{ color: "#00234B" }}
            />
          ) : (
            <img
              src={editBlack}
              className="mobile-s:h-4 mobile-s:w-4 tablet:h-8 tablet:w-8 laptop-s:h-6 laptop-s:w-6"
              style={{ color: "#00234B" }}
            />
          ))
        )}
      </Button>
      <Button
        className="border-2 border-[#CFFF92] cursor-pointer text-nowrap flex items-center gap-[10px]
    rounded-[15px] mobile-s:p-3
    laptop-s:p-3 laptop-l:p-5 laptop-l:py-3 laptop-min:p-3
    focus:outline-none focus:shadow-outline font-lato"
        onClick={() => JackPotViewDetail(pot?.deadline)}
      >
        <p
          className="mobile-s:text-[12px] mobile-m:!text-[14px] laptop-s:!text-[12px] 
        laptop-min:!text-[14px]  laptop-l:!text-[14px] text-[#CFFF92]"
        >
          View Picks
        </p>

        {/* <img src={btnIicon} className="h-5 w-5 laptop-s:h-4 laptop-s:w-4 " /> */}
      </Button>
    </div>
  );
}

function PotSelectButton({
  pot,
  potType,
  pickId,
  status,
  potStatus,
  systemPrediction,
}) {
  const [yourPick, setYourPick] = useState({
    teamName: "",
    shortName: "",
    logo: "",
  });

  useEffect(() => {
    if (pot?.pick_id?.length > 0) {
      const pick = pot.pick_id.find((pick) => pick.pick_id === pickId);
      if (pick?.pick_id === pickId) {
        setYourPick((prev) => ({
          ...prev,
          teamName: pick?.prediction?.team,
          shortName: pick?.prediction?.short_name,
          logo: pick?.prediction?.logo,
        }));
      } else {
        setYourPick((prev) => ({ ...prev, teamName: "NPY", logo: pick?.logo }));
      }
    } else {
      setYourPick((prev) => ({ ...prev, teamName: "NPY", logo: "" }));
    }
  }, [pot, pickId]);

  const logo = pot?.pick_id?.filter((ele) => {
    if (ele.pick_id === pickId) {
      return ele;
    }
  });

  return (
    <div>
      {potType === "newPot" ? (
        <NewPotButton pot={pot} potType={pot} pickId={pickId} />
      ) : potType === "activePot" ? (
        <ActivePotButton
          pot={pot}
          potType={pot}
          pickId={pickId}
          choosedPick={yourPick}
          logo={logo}
          status={status}
          potStatus={potStatus}
          systemPrediction={systemPrediction}
        />
      ) : (
        <JackPotButton pot={pot} potType={pot} pickId={pickId} />
      )}
    </div>
  );
}

export default PotSelectButton;

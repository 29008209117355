import React, { useState, useEffect } from "react";
import Button from "../../Elements/Button";
import { useDispatch, useSelector } from "react-redux";

import modelX from "../../assets/images/model-x.svg";
import teamImg1 from "../../assets/images/team-img1.png";
import teamSelectedIcon from "../../assets/images/team-selected-icon.svg";

import { chooseNewPick } from "../GreenDashboard/dashboard.slice";
import {
  fechSelectedPrevWeekTeam,
  postTeamForPick,
  putTeamForPick,
} from "../../services/dashboard/teams";

import { updatePrevWeekStatus } from "../GreenDashboard/team.slice";
import { utcTimetoLocalTimeWithzone } from "../../utils/convertTime";
import { ThreeDots } from "react-loader-spinner";
function PickTeamModel() {
  const dispatch = useDispatch();
  const [altreadyChooseTeam, setAltreadyChooseTeam] = useState("");
  const [teamDetails, setTeamDetails] = useState([
    {
      isLoading:null,
      pickFixture: [],
      selectedTeam: [],
      selectedPickId: "",
      predictionHistory: [],
    },
  ]);

  const [updateFixtures, setUpdateFixtures] = useState([]);

  const [selectedPickTeam, setSelectedTeam] = useState({
    teamType: "",
    teamId: "",
  });

  const isChoosePick = useSelector((state) => {
    return {
      choosePick: state.dashboard.choosePick.choosePick,
      pickBtnType: state.dashboard.choosePick.pickBtnType,
    };
  });
  const [isOpen, setIsOpen] = useState(isChoosePick);
  const [pickType, setPickType] = useState(""); // select pick choosepick, change pick

  useEffect(() => {
    setIsOpen(isChoosePick?.choosePick);
    setPickType(isChoosePick.pickBtnType);
  }, [isChoosePick.choosePick, isChoosePick.pickBtnType]);

  const fixture = useSelector((state) => {
    return {
      isLoading:state.teams.loading,
      potFixtures: state.teams.potFixtures,
      selectTeamPot: state.teams.selectTeamPot,
      selectedPickId: state.teams.selectedPickId,
      predictionHistory: state.teams.predictionHistory,
    };
  });

  useEffect(() => {
    setTeamDetails((prev) => ({
      ...prev,
      isLoading: fixture.isLoading,
      pickFixture: fixture.potFixtures,
      selectedTeam: fixture.selectTeamPot,
      selectedPickId: fixture.selectedPickId,
      predictionHistory: fixture.predictionHistory,
    }));
  }, [fixture.potFixtures, fixture.selectTeamPot, fixture.predictionHistory, fixture.isLoading]);

  const togglePopup = () => {
    setSelectedTeam((prev) => ({
      ...prev,
      teamType: "",
      teamId: "",
    }));
    dispatch(chooseNewPick(!isOpen));
    setIsOpen(!isOpen);
  };

  const handleClickTeamSide = async (id, teamType) => {
    await dispatch(updatePrevWeekStatus(false));
    setSelectedTeam((prev) => ({
      ...prev,
      teamType: teamType,
      teamId: id,
    }));
  };

  // select team type and call save & update  pick api
  const pickTeamHandler = () => {
    const teamType =
      selectedPickTeam.teamType === "hometeam" ? "hometeam" : "awayteam";
    if (teamType && selectedPickTeam.teamId) {
      const selectedTeam = teamDetails.pickFixture.find(
        (t) => t.id === selectedPickTeam.teamId
      );
      let pickId = Number(teamDetails?.selectedPickId);
      if (pickType === "changePick") {
        dispatch(
          putTeamForPick(`/pot/selectpicks/${pickId}`, {
            pick: pickId,
            fixture: selectedTeam?.match_id,
            prediction: selectedTeam[teamType],
            pot: teamDetails?.selectedTeam?.id,
            team: teamType ? "hometeam" : "awayteam",
          })
        );
      } else {
        dispatch(
          postTeamForPick("/pot/selectpicks", {
            pick: pickId,
            fixture: selectedTeam?.match_id,
            prediction: selectedTeam[teamType],
            pot: teamDetails?.selectedTeam?.id,
            team: teamType ? "hometeam" : "awayteam",
          })
        );
      }

      dispatch(chooseNewPick(false));
    }
    setSelectedTeam((prev) => ({
      ...prev,
      teamType: "",
      teamId: "",
    }));
  };

  const cancelTeamHandler = () => {
    dispatch(chooseNewPick(false));
    setSelectedTeam((prev) => ({
      ...prev,
      teamType: "",
      teamId: "",
    }));
  };

  useEffect(() => {
    if (
      fixture?.selectTeamPot?.pick_id?.length > 0 &&
      teamDetails.selectedPickId
    ) {
      const selectedTeam = fixture?.selectTeamPot.pick_id.filter((team) => {
        if (team.pick_id === teamDetails.selectedPickId) {
          return team;
        }
      });
      setAltreadyChooseTeam(selectedTeam.prediction);
    }
  }, [
    fixture.selectTeamPot,
    fixture.selectedPickId,
    teamDetails.selectedPickId,
  ]);

  useEffect(() => {
    if (teamDetails.selectedPickId) {
      let newFixtures;

      if (teamDetails?.pickFixture?.length > 0) {
        newFixtures = teamDetails?.pickFixture?.map((team) => {
          let updatedTeam = { ...team }; // Clone the team object

          updatedTeam.hometeamDisable = false;
          updatedTeam.awayteamDisable = false;

          updatedTeam.hometeamCurrentMatchSelected = false;
          updatedTeam.awayteamCurrentMatchSelected = false;

          teamDetails?.predictionHistory.forEach((prediction) => {
            let predictionTeamName = prediction.prediction;

            if (prediction.current_match_week === false) {
              if (predictionTeamName === team.hometeam) {
                updatedTeam.hometeamDisable = true;
              }
              if (predictionTeamName === team.awayteam) {
                updatedTeam.awayteamDisable = true;
              }
            }

            if (prediction.current_match_week === true) {
              if (predictionTeamName === team.hometeam) {
                updatedTeam.hometeamCurrentMatchSelected = true;
              }
              if (predictionTeamName === team.awayteam) {
                updatedTeam.awayteamCurrentMatchSelected = true;
              }
            }
          });
          return updatedTeam;
        });
        if (newFixtures.length > 0) {
          setUpdateFixtures(newFixtures);
        }
      }
    }
  }, [teamDetails?.pickFixture, teamDetails.predictionHistory]);

  const [isMobile, setIsMobile] = useState(false);
  const [isLaptop, setIsLaptop] = useState(false);
  const [isTablet, setIsTablet] = useState(false);

  useEffect(() => {
    const handleResize = () => {
      const isMobileResolution = window.innerWidth <= 765;
      const isLaptopResolution = window.innerWidth >= 1023;
      setIsLaptop(isLaptopResolution);
      setIsMobile(isMobileResolution);
    };
    handleResize();
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <div className="background-color: rgba(255, 255, 255, 0.5);">
      {isOpen && (
         <div className="fixed mobile-s:overflow-auto inset-0 flex items-center justify-center z-50">
         <div className="absolute inset-0 bg-gray-800 opacity-75 p-8"></div>
         <div
           className={`relative  overflow-auto mobile-s:w-[95%] tablet:w-[85%] laptop-s:w-[45%] laptop-min:w-[45%] laptop-s:h-[95%] ${
            updateFixtures?.length > 15 &&
             "mobile-s:mt-[65%] mobile-s:mb-[4%]"
           } ${
            updateFixtures?.length > 17 &&
             "mobile-s:mt-[75%] mobile-s:mb-[4%]"
           } tablet:mt-0 tablet:mb-0  laptop-l:w-[45%] laptop-l:h-[90%] desktop:w-[60%] desktop:h-[60%] bg-[#F8F8F8] p-4 shadow-lg rounded-[12px]`}
         >
            <button
              className="absolute top-0 p-2 right-0 mt-2 mr-2 text-gray-600 hover:text-gray-800"
              onClick={togglePopup}
            >
              <img src={modelX} alt="Close" />
            </button>
            {/* {
              updateFixtures?.length === 0 && fixture.isLoading && <div className="flex items-center justify-center">
              <ThreeDots
                visible={true}
                height="80"
                width="80"
                color="#4fa94d"
                radius="9"
                ariaLabel="three-dots-loading"
                wrapperStyle={{}}
                wrapperClass=""
              />
            </div>
            } */}
            <div>
              <div className="flex items-center justify-center gap-[2%]">
                <h2 className="text-[#000000] text-[22px]">Pick your team</h2>
              </div>
              <div className="px-[2%] mt-[5%]">
                {updateFixtures?.length > 0 ? (
                  updateFixtures.map(
                    ({
                      id,
                      hometeam,
                      awayteam,
                      hometeamDisable,
                      awayteamDisable,
                      awayteamCurrentMatchSelected,
                      hometeamCurrentMatchSelected,
                      awayteam_goal,
                      hometeam_goal,
                      start,
                      hometeam_logo,
                      awayteam_logo,
                      team_details,
                      short
                    }) => {
                      // const homeTeamCode = team_details?.find(
                      //   (ele) => ele.name === hometeam
                      // );
                      // const awayTeamCode = team_details?.find(
                      //   (ele) => ele.name === awayteam
                      // );
                      let homeTeamCode = short.find(
                        (ele) => ele.team_type === "home"
                      );
                      let awayTeamCode = short.find(
                        (ele) => ele.team_type === "away"
                      );
                      const date = utcTimetoLocalTimeWithzone(start).split(" ");
                      const time = `${date[3].split(":")[0]}:${
                        date[3].split(":")[1]
                      }`;
                      const concatDate = `${date[1]} ${date[2]} ${time} ${date[4]}`;
                      const mobileDate = `${date[1]} ${date[2]}`;
                      return (
                        <div className="flex mt-[1%] gap-[8%]" key={id}>
                          <div
                            className={`left-side hover:border-blue-500 hover:border-[1px] hover:bg-[#0000001A] hover:rounded-[5px] cursor-pointer mobile-s:p-[5%] mobile-s:px-[1px] tablet:p-[4%] laptop-l:!p-[2%] overflow-hidden ${
                              hometeamDisable
                                ? "text-[#0000005A]"
                                : (selectedPickTeam.teamId === id &&
                                    selectedPickTeam.teamType === "hometeam") ||
                                  hometeamCurrentMatchSelected
                                ? "border-blue-500 border-[1px] bg-[#0000001A] rounded-[5px]"
                                : ""
                            } transition-all duration-300 ease-in-out`}
                            onClick={() => {
                              if (!hometeamDisable) {
                                handleClickTeamSide(id, "hometeam");
                              }
                            }}
                            style={{ flex: "40%" }}
                            title={
                              hometeamDisable
                                ? "This team already selected in previous week"
                                : ""
                            }
                          >
                            <div className="flex items-center h-[10px] gap-2">
                              <img
                                src={hometeam_logo}
                                alt="Team Image"
                                className={`laptop-s:w-[25px] laptop-s:h-[25px] mobile-s:w-[20px] mobile-s:h-[20px] tablet:w-[20px] tablet:h-[20px] ${
                                  hometeamDisable ? "opacity-50" : ""
                                }`}
                              />
                              <p className="mobile-s:text-[10px] laptop-s:text-[13px] tablet:text-[12px] laptop-l:text-[15px] laptop-min:text-[12px] text-nowrap">
                                {homeTeamCode?.code}
                              </p>
                              {(selectedPickTeam.teamId === id &&
                                selectedPickTeam.teamType === "hometeam") ||
                              hometeamCurrentMatchSelected ? (
                                <img
                                  src={teamSelectedIcon}
                                  className="ml-[3%] mt-[1%] w-[20px] h-[20px]"
                                  alt="Selected"
                                />
                              ) : null}
                            </div>
                          </div>

                          <div
                            className="flex p-[1%] justify-center"
                            style={{ flex: "20%" }}
                          >
                            <div
                              className="flex tablet:text-[10px] laptop-s:text-[12px] laptop-l:text-[15px] justify-center"
                              style={{ flex: "15%" }}
                            >
                              <div className="flex p-2 mobile-l:w-[100px] tablet:w-[180px] laptop-l:w-[180px] bg-[#9491911a] justify-center items-center rounded-[4px]">
                                {awayteam_goal === "None" ? (
                                  <p className="flex justify-center text-nowrap mobile-s:text-[8px] mobile-m:text-[10px] tablet:text-[12px]">
                                    {hometeam_goal === "None"
                                      ? isMobile
                                        ? mobileDate
                                        : concatDate
                                      : 0}
                                  </p>
                                ) : (
                                  <p className="flex items-center justify-center">
                                    {hometeam_goal} | {awayteam_goal}
                                  </p>
                                )}
                              </div>
                            </div>
                          </div>

                          <div
                            className={`right-side hover:border-blue-500 hover:border-[1px] hover:bg-[#0000001A] hover:rounded-[5px] cursor-pointer mobile-s:p-[5%] mobile-s:px-[1px] tablet:p-[4%] laptop-l:p-[2%] overflow-hidden ${
                              awayteamDisable
                                ? "text-[#0000005A]"
                                : (selectedPickTeam.teamId === id &&
                                    selectedPickTeam.teamType === "awayteam") ||
                                  awayteamCurrentMatchSelected
                                ? "border-blue-500 border-[1px] bg-[#0000001A] rounded-[5px]"
                                : ""
                            } transition-all duration-300 ease-in-out`}
                            onClick={() => {
                              if (!awayteamDisable) {
                                handleClickTeamSide(id, "awayteam");
                              }
                            }}
                            style={{ flex: "40%", textAlign: "right" }}
                            title={
                              awayteamDisable
                                ? "This team already selected in previous week"
                                : ""
                            }
                          >
                            <div className="flex items-center h-[10px] gap-2 justify-end">
                              {(selectedPickTeam.teamId === id &&
                                selectedPickTeam.teamType === "awayteam") ||
                              awayteamCurrentMatchSelected ? (
                                <img
                                  src={teamSelectedIcon}
                                  className="ml-[3%] w-[20px] h-[20px]"
                                  alt="Selected"
                                />
                              ) : null}
                              <p className="mobile-s:text-[10px] laptop-s:text-[13px] tablet:text-[12px] laptop-l:text-[15px] text-nowrap">
                                {awayTeamCode?.code}
                              </p>
                              <img
                                src={awayteam_logo}
                                alt="Team Image"
                                className={`laptop-s:w-[25px] laptop-s:h-[25px] mobile-s:w-[20px] mobile-s:h-[20px] tablet:w-[20px] tablet:h-[20px] ${
                                  awayteamDisable ? "opacity-50" : ""
                                }`}
                              />
                            </div>
                          </div>
                        </div>
                      );
                    }
                  )
                ) : (
                  <div className="flex justify-center items-center h-[70vh]">
                    <h4>No Fixtures there.</h4>
                  </div>
                )}
              </div>
            </div>
            {updateFixtures?.length > 0 && (
              <div className="flex justify-center mt-[5%]">
                <Button
                  className="bg-blue-500 px-[4%] py-[1%] justify-center hover:bg-blue-700 text-white font-bold focus:outline-none focus:shadow-outline mx-[1%] mt-[3%] rounded-[10px]"
                  type="button"
                  onClick={pickTeamHandler}
                >
                  Confirm
                </Button>
                <Button
                  className="bg-[#0000003A] text-[#0000004A] justify-center px-[5%] py-[2%] hover:bg-[#0000004A] text-white font-bold focus:outline-none focus:shadow-outline mx-[1%] mt-[3%] rounded-[10px]"
                  type="button"
                  onClick={cancelTeamHandler}
                >
                  Cancel
                </Button>
              </div>
            )}
          </div>
        </div>
      )}
    </div>
  );
}

export default PickTeamModel;

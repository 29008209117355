import React, { useState, useEffect, useMemo } from "react";

function useFindDevice() {
  const [isMob, setIsMob] = useState(false);
  const [isTab, setIsTab] = useState(false);
  const [isLaptop, setIsLaptop] = useState(false);
  const [isLogedIn, setIsLogedIn] = useState(false);

  useEffect(() => {
    if (window.innerWidth <= 767) {
      setIsMob(true);
    } else {
      setIsMob(false);
    }
    if (window.innerWidth >= 768 && window.innerWidth <= 1057) {
      setIsTab(true);
    }
    if (window.innerWidth >= 1024) {
      setIsLaptop(true);
    }
  }, []);

  const local = useMemo(() => JSON.parse(localStorage.getItem("userInfo")), []);

  useEffect(() => {
    if (local?.userLogedIn) {
      setIsLogedIn(true);
    } else {
      setIsLogedIn(false);
    }
  }, [local]);

  return { isMob, isTab, isLaptop, isLogedIn, email:local?.email };
}

export default useFindDevice;

import React, { useState, useEffect } from "react";
import CryptoJS from "crypto-js";
import OtpInput from "react-otp-input";
import Countdown from "react-countdown";
import Card from "../../../Elements/Card";
import Button from "../../../Elements/Button";
import AuthLayout from "../../AuthLayout/AuthLayout";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  resendOTPCode,
  verifyOTPCode,
  activateAccount,
} from "../../../services/auth/verification";
import PlayerLayout from "../../PlayerLayout/PlayerLayout";
import useFindDevice from "../../CustomHooks/FindDevice";

import { RotatingLines } from "react-loader-spinner";
import paymentSuccImg from "../../../assets/card/payment-succ-img.jpg";

import paymentFailed from "../../../assets/card/payment-failed.jpg";
import { createPaymentRequest } from "../../../services/payment/payment";

function VerificationLoading() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [orderId, setOrderId] = useState("");

  const { email } = useFindDevice();
  const [payentStatus, setPaymentStatus] = useState("loading");

  let withdrawAmount = useSelector((state) => state.payment.withdrawAmount);
  useEffect(() => {
    createPamentApi();
  }, []);

  const calculateChecksum = (withdrawAmount) => {
    const dataToSign = JSON.stringify(withdrawAmount);
    const hash = CryptoJS.SHA256(dataToSign);
    const hashHex = hash.toString(CryptoJS.enc.Hex);
    console.log("hashHex", hashHex);
    return hashHex;
  };

  async function createPamentApi() {
    let check = { amount: withdrawAmount };
    const checksum = calculateChecksum(check);
    let res = await dispatch(
      createPaymentRequest("/pot/requestpayout", {
        amount: withdrawAmount,
        checkHeader: checksum,
      })
    );
    if (res?.head?.applyStatus === "SUCCESS" && res?.head?.code == "0") {
      setPaymentStatus("success");
      setOrderId(res?.body?.transferToBankOrder?.orderNo);
    } else {
      setPaymentStatus("failed");
      setOrderId("");
    }
  }
  function renderCard() {
    if (payentStatus === "loading") {
      return (
        <div className="flex flex-col">
          <div className="flex justify-center">
            {" "}
            <RotatingLines
              visible={true}
              height="96"
              width="96"
              color="grey"
              strokeWidth="5"
              animationDuration="0.75"
              ariaLabel="rotating-lines-loading"
              wrapperStyle={{}}
              wrapperClass=""
            />
          </div>

          <div className="flex flex-col">
            <h2 className="text-[28px]">You transaction is in progress</h2>
            <p className="text-[15px]">
              Do not press Back or close the window during this process
            </p>
          </div>
        </div>
      );
    } else if (payentStatus === "success") {
      return (
        <div className="flex flex-col">
          <div className="flex justify-center">
            <img src={paymentSuccImg} className="h-20 w-20" />
          </div>

          <div className="flex flex-col">
            <h2 className="text-[28px] text-center">Transaction Successful</h2>
            <p className=" text-[15px] text-center">
              <p> Thank you!</p>
              <p>
                Your payment was successfully initiated.{" "}
                <p className="mt-1 font-semibold">
                  {orderId && `Transaction ID  ${orderId}`}
                </p>
              </p>
              <p className="mt-2">
                Your account will be credited in accordance with your bank's
                processing time.
              </p>
            </p>
          </div>
          <div className="flex justify-center">
            <Button
              className={` text-nowrap justify-center text-[#FFFFFF] mt-10 bg-[#0081F8] w-[40%] hover:bg-[#0081F8] flex items-center gap-[10px]
       rounded-[5px] mobile-s:p-3 
        laptop-min:px-3 laptop-l:px-5 laptop-l:py-3`}
              title="Choose a new team"
              onClick={() => {
                navigate("/dashboard");
              }}
            >
              Return Home
            </Button>
          </div>
        </div>
      );
    } else {
      return (
        <div className="flex flex-col">
          <div>
            <div className="flex justify-center">
              <img src={paymentFailed} className="h-20 w-20" />
            </div>

            <div className="flex flex-col">
              <h2 className="text-[28px] text-center text-[red]">
                Transaction Failed
              </h2>
              <p className=" text-[15px] text-center">
                <p>
                  Sorry! We are unable to process the payment.Please try again
                  later
                </p>
              </p>
            </div>
          </div>
          <div className="flex justify-center">
            <Button
              className={`text-nowrap w-[40%] mt-10 justify-center cursor-pointer flex items-center gap-[10px]
    rounded-[15px]
    laptop-l:p-5 laptop-l:py-3 laptop-s:p-3 tablet:p-3 mobile-s:px-3 mobile-s:py-1
    focus:outline-none focus:shadow-outline
    border border-[#0081F8]`}
              onClick={() => {
                navigate("/withdraw");
              }}
            >
              Retry
            </Button>
          </div>
        </div>
      );
    }
  }
  return (
    <PlayerLayout>
      <Card
        className="m-auto bg-[#FFFFFF]  mobile-s:p-10 tablet:p-20 mobile-s:w-[90%] mobile-s:mt-[10%] font-[inter]
        mobile-l:mt-[30%] mobile-l:w-[80%]
      mobile-s:h-[50%] shadow-md rounded-[12px] tablet:h-[45%] tablet:mt-[10%] 
         tablet:w-[45%] laptop-s:h-[60%] laptop-s:w-[45%] 
       laptop-s:mt-[5%] laptop-l:!mt-[10%]
       laptop-min:h-[70%]  laptop-min:w-[40%] laptop-l:w-[30%] laptop-l:h-[55%] 
       desktop:w-[20%] desktop:h-[60%]
          flex flex-col justify-center"
      >
        {renderCard()}
      </Card>
    </PlayerLayout>
  );
}

export default VerificationLoading;

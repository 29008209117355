import {
  fetchPotInfo,
  fetchMyPotStart,
  fetchMyPotSuccess,
  fetchMyPotFailure,
  fetchAllMatchWeek,
  fetchMyPickWeek,
  fetchStandingDataSucc,
  fetchOtherStandingDataSucc,
  updatemodifiedFixture,
} from "../../Components/MyPotViewDetail/myPot.slice";
import { postData, getData, putData } from "../api";

export const fetchPotInfoMyPotView = (URL, tokenInfo) => async (dispatch) => {
  try {
    // dispatch(fetchMyPotStart());
    const response = await getData(URL);
    if (response.status === "Success") {
      dispatch(fetchPotInfo(response?.data));
    }
  } catch (error) {
    dispatch(fetchMyPotFailure(error.message));
  }
};

export const fetchMatchWeek = (URL, tokenInfo) => async (dispatch) => {
  try {
    // dispatch(fetchMyPotStart());
    const response = await getData(URL);
    if (response.status === "Success") {
      dispatch(fetchAllMatchWeek(response.data));
    }
  } catch (error) {
    dispatch(fetchMyPotFailure(error.message));
  }
};

export const fetchMyPicks = (URL, tokenInfo) => async (dispatch) => {
  try {
    dispatch(fetchMyPotStart());
    const response = await getData(URL);
    if (response.status === "Success") {
      console.log('pick match prediction', response.data)
      dispatch(fetchMyPickWeek(response.data));
    }
  } catch (error) {
    dispatch(fetchMyPotFailure(error.message));
  }
};

export const fetchStandingData = (URL, tokenInfo) => async (dispatch) => {
  try {
    // dispatch(fetchMyPotStart());
    const response = await getData(URL);
    if (response.status === "Success") {
      dispatch(fetchStandingDataSucc(response.data));
    }
  } catch (error) {
    dispatch(fetchMyPotFailure(error.message));
  }
};

export const fetchOtherStandingData = (URL, tokenInfo) => async (dispatch) => {
  try {
    // dispatch(fetchMyPotStart());
    const response = await getData(URL);
    if (response.status === "Success") {
      dispatch(fetchOtherStandingDataSucc(response.data));
    } else {
      dispatch(fetchOtherStandingDataSucc([]));
    }
  } catch (error) {
    dispatch(fetchMyPotFailure(error.message));
  }
};

export const putTeamForPickMyPot = (URL, fixtureInfo) => async (dispatch) => {
  try {
    // dispatch(fetchMyPotStart());
    const response = await putData(URL, fixtureInfo);
    if (response.status === "Success") {
      response.data.fixtureInfo = fixtureInfo;
      dispatch(updatemodifiedFixture(response.data));
    } else {
      // dispatch(fetchDashboardFailure(response.data.non_field_errors[0]))
      // dispatch(fetchDashboardFailure(response.data.message));
    }
  } catch (error) {
    dispatch(fetchMyPotFailure(error.message));
  }
};

export const postTeamForPickMyPot = (URL, fixtureInfo) => async (dispatch) => {
  try {
    // dispatch(fetchMyPotStart());
    const response = await postData(URL, fixtureInfo);
    if (response.status === "Success") {
      response.data.fixtureInfo = fixtureInfo;
      dispatch(updatemodifiedFixture(response.data));
    } else {
      // dispatch(fetchDashboardFailure(response.data.non_field_errors[0]))
      // dispatch(fetchDashboardFailure(response.data.message));
    }
  } catch (error) {
    dispatch(fetchMyPotFailure(error.message));
  }
};

export const fetchPickStatus = (URL) => async (dispatch) => {
  try {
    // dispatch(fetchMyPotStart());
    const response = await getData(URL);
    if (response.status === "Success") {
      return response.data;
    }
  } catch (error) {
    dispatch(fetchMyPotFailure(error.message));
  }
};
